<template>
  <v-card width="400" class="mx-auto mt-auto mb-auto">
    <v-card-title>
      <h3>You have successfully logged out.</h3>
      Please clear your browser cache.
    </v-card-title>
    <v-divider></v-divider>
    <v-card-actions>
      <v-btn color="accent" @click="loginAgain()">Login Again</v-btn>
      <v-spacer></v-spacer>
      <v-btn color="warn" @click="cancel()">Cancel</v-btn>
      <v-btn color="primary" @click="logout()">OK</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>

export default {
  name: "LogoutPage",
  data: () => ({
    //
  }),
  methods: {
    cancel: function () {
      this.$router.go(-1);
    },
    logout: function () {
      this.$store.dispatch('authStore/logout',false)
      this.$router.push('/home');
    },
    loginAgain: function() {
      this.$store.dispatch('authStore/logout',false)
      this.$router.push('/login');
    }
  }

}
</script>

<style scoped>

</style>